import { Context, useMutation, useTranslation } from '@obvio/app'
import { Form, Grid, Stack, TextInput, toast } from '@obvio/ui'
import { useCallback } from 'react'

import { Arrow } from '../Arrow'
import { SpaSelector } from '../InlineSpaReservation/SpaSelector'
import { FormContent } from './NavFormEvent/components'
import {
  SignTitle,
  StyledCheckboxInput,
  StyledSubmit,
  Title,
} from './NavFormRestaurant/components'

import type { AllowUndefinedForOptional, StateSetter } from '@obvio/utils'
import type { ReactElement } from 'react'

type NavFormSpaProps = AllowUndefinedForOptional<{
  setConfirmed: StateSetter<boolean>
  defaultValue?: string
}>

export function NavFormSpa({
  setConfirmed,
  defaultValue,
}: NavFormSpaProps): ReactElement {
  const [reserveMutation] = useMutation('reserveSpa')

  const { t } = useTranslation()

  const handleSubmit = useCallback(
    async (data: {
      name: string
      phone: string
      date: string
      kind: string
    }) => {
      await reserveMutation(data)
      setConfirmed(true)
      toast.success(t('spaSignup.form.success'))
    },
    [reserveMutation, t, setConfirmed],
  )
  return (
    <FormContent>
      <Context name="navForm">
        <Stack kind="vertical" spacing="large">
          <SignTitle>ZAPISZ SIĘ NA ZABIEG</SignTitle>
          <Title tag="h2">
            <b>511 ORGANIC</b>
            MEDI SPA:
          </Title>
          <Stack kind="vertical" spacing="large">
            <Form onSubmit={handleSubmit} contextOnly>
              <SpaSelector defaultValue={defaultValue} nav />
              <TextInput name="name" label="Imię i nazwisko" />
              <Grid templateColumns="repeat(2,minmax(0,1fr))" gap="large">
                <TextInput name="phone" label="Telefon" />
                <TextInput name="date" label="Wpisz dogodne terminy" />
              </Grid>
              <StyledCheckboxInput
                noSubmit
                optional={false}
                name="tos"
                label="Zapoznał_m się z regulaminem zapisów i wyrażam zgodę na kontakt telefoniczny przez Poziom 511 Sp. z o.o. z siedzibą w Ogrodzieńcu w celach realizacji usług i sprzedaży produktów."
              />
              <StyledSubmit kind="transparent">
                <Stack>
                  <span>Zapisz</span>
                  <Arrow direction="right" length="7rem" />
                </Stack>
              </StyledSubmit>
            </Form>
          </Stack>
        </Stack>
      </Context>
    </FormContent>
  )
}
