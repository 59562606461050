import { styled, useTranslation } from '@obvio/app'
import { SvgArrowSimple } from '@obvio/svg'
import { Stack } from '@obvio/ui'
import { motion } from 'framer-motion'

import type { ReactElement } from 'react'

const ScrollStack = styled(Stack)`
  z-index: 1;
  align-items: center;
`

const ArrowWrap = styled.div`
  > svg {
    transform: rotateZ(90deg);
  }
`
const ArrowBounce = motion(ArrowWrap)

export function ScrollMore(): ReactElement {
  const { t } = useTranslation()

  return (
    <ScrollStack>
      <span>{t('label.scroll')}</span>
      <ArrowBounce
        animate={{ y: [0, 5] }}
        transition={{ repeat: Infinity, repeatType: 'mirror' }}
      >
        <SvgArrowSimple />
      </ArrowBounce>
    </ScrollStack>
  )
}
