import { useTranslation } from '@obvio/app'
import { SvgArrowSimple } from '@obvio/svg'
import { Form, GridItem, Stack } from '@obvio/ui'
import { useReducer, useState } from 'react'
import TimeField from 'react-simple-timefield'

import { DateField } from '../InlineBookCalendar/DateField'
import { InlineBarElement } from '../InlineBookCalendar/InlineBarElement'
import { NumberField } from '../InlineBookCalendar/NumberField'
import {
  CheckButton,
  CheckCell,
  ContentWrap,
  MobileButton,
  Panel,
  TimeInputStyle,
} from '../InlineBookCalendar/styles'
import { NavForm } from '../NavForms/NavForm'
import { NavFormRestaurant } from '../NavForms/NavFormRestaurant'

import type { ReactElement } from 'react'

// Helpers for initial values
const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const initialState = {
  date: today,
  time: '16.00',
  guests: 2,
}

type State = typeof initialState

type Action =
  | { type: 'DATE'; payload: Date }
  | { type: 'TIME'; payload: string }
  | { type: 'GUESTS'; payload: number }

const reducer = (state: typeof initialState, action: Action): State => {
  switch (action.type) {
    case 'DATE':
      return { ...state, date: action.payload }
    case 'TIME':
      return { ...state, time: action.payload }
    case 'GUESTS':
      return { ...state, guests: action.payload }
    default:
      throw new Error('Incorrect action.type')
  }
}

export function InlineRestaurantReservation(): ReactElement {
  const { t } = useTranslation()
  const [formOpened, setFormOpened] = useState(false)
  const [{ date, time, guests }, dispatch] = useReducer(reducer, initialState)

  return (
    <>
      <Form contextOnly>
        <Panel>
          <ContentWrap templateColumns="repeat(4,minmax(0,1fr))">
            <GridItem noCenter>
              <InlineBarElement title="PRZYJAZD">
                <DateField
                  value={date}
                  update={(payload) => dispatch({ type: 'DATE', payload })}
                />
              </InlineBarElement>
            </GridItem>
            <GridItem noCenter>
              <InlineBarElement title="GODZINA">
                <TimeField
                  value={time}
                  onChange={(_, value) =>
                    dispatch({ type: 'TIME', payload: value })
                  }
                  input={<TimeInputStyle />}
                  colon="."
                />
              </InlineBarElement>
            </GridItem>

            <GridItem noCenter>
              <InlineBarElement title="ILOŚĆ OSÓB">
                <NumberField
                  value={guests}
                  update={(payload) => dispatch({ type: 'GUESTS', payload })}
                />
              </InlineBarElement>
            </GridItem>

            <CheckCell noCenter>
              <CheckButton
                type="submit"
                kind="transparent"
                onClick={() => setFormOpened(true)}
              >
                <span>Rezerwuj stolik</span>
                <SvgArrowSimple />
              </CheckButton>
            </CheckCell>
          </ContentWrap>
          <MobileButton
            onClick={() => setFormOpened(true)}
            kind={['transparent']}
          >
            <Stack>
              <span>Rezerwuj stolik</span>
              <SvgArrowSimple />
            </Stack>
          </MobileButton>
        </Panel>
      </Form>
      {formOpened && (
        <NavForm
          title="RESTAURACJA"
          close={() => setFormOpened(false)}
          confirmText={t('restaurantSignup.form.success')}
        >
          {(setConfirmed) => (
            <NavFormRestaurant
              date={date}
              guests={guests}
              time={time}
              setConfirmed={setConfirmed}
            />
          )}
        </NavForm>
      )}
    </>
  )
}
