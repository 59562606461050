import {
  Context,
  css,
  useIsMobile,
  useMutation,
  useTranslation,
} from '@obvio/app'
import {
  DateInput,
  Form,
  Grid,
  NumberInput,
  Stack,
  TextInput,
  toast,
} from '@obvio/ui'
import { useCallback } from 'react'

import { Arrow } from '../Arrow'
import { calendarStyle, poziomCalendarProps } from '../Calendar'
import { FormContent } from './NavFormEvent/components'
import {
  SignTitle,
  StyledCheckboxInput,
  StyledSubmit,
  Title,
} from './NavFormRestaurant/components'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type NavFormRestaurantProps = AllowUndefinedForOptional<{
  date?: Date
  time?: string
  guests?: number
  setConfirmed: (confirmed: boolean) => void
}>

const dropdownCss = css`
  background: transparent;
  border-radius: 0;
`

export function NavFormRestaurant({
  date,
  time,
  guests,
  setConfirmed,
}: NavFormRestaurantProps): ReactElement {
  const [reserveMutation] = useMutation('reserveRestaurant')

  const { t } = useTranslation()

  const handleSubmit = useCallback(
    async (data: {
      name: string
      phone: string
      date: string
      time: string
      guests: number
    }) => {
      await reserveMutation(data)
      setConfirmed(true)
      toast.success(t('restaurantSignup.form.success'))
    },
    [reserveMutation, setConfirmed, t],
  )

  const isMobile = useIsMobile()

  return (
    <FormContent>
      <Context name="navForm">
        <Stack kind="vertical" spacing="large">
          <SignTitle>ZAPISZ SIĘ DO RESTAURACJI</SignTitle>
          <Title tag="h2">
            <b>511 RESTAURACJA</b>
          </Title>
          <Stack kind="vertical" spacing="large">
            <Form onSubmit={handleSubmit} contextOnly>
              <Grid
                templateColumns={isMobile ? 'auto' : 'repeat(2,minmax(0,1fr))'}
                gap={isMobile ? 'extraLarge' : 'large'}
              >
                <TextInput label="Imię i nazwisko" name="name" />
                <TextInput label="Number telefonu" name="phone" />
              </Grid>
              <Grid
                templateColumns={isMobile ? 'auto' : 'repeat(3,minmax(0,1fr))'}
                gap={isMobile ? 'extraLarge' : 'large'}
              >
                <DateInput
                  calendarProps={{ ...poziomCalendarProps, css: calendarStyle }}
                  dropdownProps={{ css: dropdownCss }}
                  defaultValue={date ?? new Date()}
                  label="Data"
                  name="date"
                />
                <TextInput
                  defaultValue={time ?? '16.00'}
                  name="time"
                  label="Godzina"
                />
                <NumberInput
                  name="guests"
                  label="Ilość osób"
                  defaultValue={guests}
                />
              </Grid>
              <StyledCheckboxInput
                noSubmit
                optional={false}
                name="tos"
                label="Zapoznał_m się z regulaminem zapisów i wyrażam zgodę na kontakt telefoniczny przez Poziom 511 Sp. z o.o. z siedzibą w Ogrodzieńcu w celach realizacji usług i sprzedaży produktów."
              />
              <StyledSubmit kind="transparent">
                <Stack>
                  <span>Zapisz</span>
                  <Arrow direction="right" length="7rem" />
                </Stack>
              </StyledSubmit>
            </Form>
          </Stack>
        </Stack>
      </Context>
    </FormContent>
  )
}
