import { styled, css, useQuery, mobile } from '@obvio/app'
import { SelectInput } from '@obvio/ui'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const Selector = styled(SelectInput)<{ $nav: boolean }>`
  font-size: 2.25rem;
  input {
    border: none;
    height: unset;
    padding: 0;
    color: ${(theme, { $nav }) => ($nav ? theme.colors.secondary : 'white')};
  }
  svg > * {
    fill: ${(theme, { $nav }) => ($nav ? theme.colors.secondary : 'white')};
  }
  @media ${mobile} {
    font-size: 1rem;
  }
`

type SpaSelectorProps = AllowUndefinedForOptional<{
  nav?: boolean
  defaultValue?: string
}>

const dropdownCss = css`
  background: ${(theme) => theme.colors.primary};
  label {
    background: transparent !important;
  }
  button {
    background: ${(theme) => theme.colors.primary};
    color: ${(theme) => theme.colors.secondary};
    padding: 2rem;
    font-size: 1.25rem;
  }
`

export function SpaSelector({
  nav,
  defaultValue,
}: SpaSelectorProps): ReactElement | null {
  const { data: spaOffers, loading } = useQuery('getSpaOffer', {
    select: {
      title: true,
    },
  })

  if (loading) {
    return null
  }

  return (
    <Selector
      $nav={Boolean(nav)}
      dropdownCss={dropdownCss}
      name="kind"
      defaultValue={defaultValue ?? spaOffers?.[0].title}
    >
      {spaOffers?.map((offer) => (
        <SelectInput.Option key={offer.id} value={offer.title}>
          {offer.title}
        </SelectInput.Option>
      ))}
    </Selector>
  )
}
