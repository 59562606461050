import { useScrollLockManual } from '@obvio/app'
import { FadeIn } from '@obvio/template'
import { forwardRef, useEffect, useState } from 'react'

import { Block, Content, Wrap } from './NavForm/components'
import { NavFormWithConfirm } from './NavFormWithConfirm'
import { ExpandMorePanel } from '../Nav/NavExpandMorePanel'

import type { AllowUndefinedForOptional, StateSetter } from '@obvio/utils'
import type { ReactElement } from 'react'

type NavFormProps = AllowUndefinedForOptional<{
  title: string
  confirmText?: string
  children: (setConfirmed: StateSetter<boolean>) => ReactElement
  close(): void
}>

export const NavForm = forwardRef<HTMLDivElement, NavFormProps>(
  ({ title, close, confirmText, children }, ref) => {
    const [confirmed, setConfirmed] = useState(false)
    const [, setLocked] = useScrollLockManual(true)

    useEffect(() => {
      return () => setLocked(false)
    })

    return (
      <Wrap ref={ref}>
        <Block />
        <ExpandMorePanel onClick={close} opened title={title} />
        <Content>
          <NavFormWithConfirm confirmed={confirmed} text={confirmText}>
            <FadeIn>{children(setConfirmed)}</FadeIn>
          </NavFormWithConfirm>
        </Content>
      </Wrap>
    )
  },
)
