import { useTranslation } from '@obvio/app'
import { SvgArrowSimple } from '@obvio/svg'
import { Form, GridItem, Stack } from '@obvio/ui'
import { useState } from 'react'

import { InlineBarElement } from '../InlineBookCalendar/InlineBarElement'
import {
  CheckCell,
  CheckSubmit,
  ContentWrap,
  MobileButton,
  Panel,
} from '../InlineBookCalendar/styles'
import { NavForm } from '../NavForms/NavForm'
import { NavFormSpa } from '../NavForms/NavFormSpa'
import { SpaSelector } from './SpaSelector'

import type { ReactElement } from 'react'

export function InlineSpaReservation(): ReactElement {
  const { t } = useTranslation()
  const [formOpened, setFormOpened] = useState<string | null | true>(null)

  return (
    <>
      <Form contextOnly onSubmit={({ kind }) => setFormOpened(kind)}>
        <Panel>
          <ContentWrap templateColumns="auto auto">
            <GridItem noCenter>
              <InlineBarElement title="ZABIEG">
                <SpaSelector />
              </InlineBarElement>
            </GridItem>
            <CheckCell noCenter>
              <CheckSubmit type="submit" kind="transparent">
                Rezerwuj SPA
              </CheckSubmit>
              <SvgArrowSimple />
            </CheckCell>
          </ContentWrap>
          <MobileButton
            kind={['transparent']}
            onClick={() => setFormOpened(true)}
          >
            <Stack>
              <span>Rezerwuj SPA</span>
              <SvgArrowSimple />
            </Stack>
          </MobileButton>
        </Panel>
      </Form>
      {formOpened && (
        <NavForm
          title="SPA"
          close={() => setFormOpened(null)}
          confirmText={t('spaSignup.form.success')}
        >
          {(setConfirmed) => (
            <NavFormSpa
              defaultValue={
                typeof formOpened === 'string' ? formOpened : undefined
              }
              setConfirmed={setConfirmed}
            />
          )}
        </NavForm>
      )}
    </>
  )
}
